@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.td {
		@apply px-6 py-4 whitespace-nowrap
	}
	.thead {
		@apply h-10 border-b border-border text-xs text-black_opacity_40
	}
}

body {
  font-family: Poppins !important;
  margin: 0;
	padding: 0;
	width: 100%;
	overflow-x: hidden !important;
	font-weight: 300;
}
#root ,html, body {
  height: 100% !important;
}
h1, h2, h3, h4, h5, h6,input, textarea, button {
	font-family: Poppins !important;
	margin: 0;
}
li {
	list-style: none;
}
a {
	text-decoration: none;
}

input {
	outline: none !important;
}
* {
	outline: none !important;
}
ul {
	padding: 0px;
}
textarea {
	resize: none;
	outline: none
}
.back_left_side {
	background: linear-gradient(360deg, #003CB5 0%, rgba(0, 60, 181, 0) 42.4%), url(./assets/images/back_login.png);
}
.active_side {
	filter: brightness(0) saturate(100%) invert(19%) sepia(94%) saturate(1161%) hue-rotate(207deg) brightness(89%) contrast(92%);
}
.rmdp-shadow {
	box-shadow: none !important;
	border: 1px solid rgba(0,0,0,0.1) !important;
}
.highlight-gray {
	color: #ccc8c8
}