@font-face
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    src: url('Poppins-Black.eot');
    src:    url('Poppins-Black.eot?#') format('embedded-opentype'),
        url('Poppins-Black.woff') format('woff'),
        url('Poppins-Black.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
    src: url('Poppins-BlackItalic.eot');
     src:   url('Poppins-BlackItalic.eot?#') format('embedded-opentype'),
        url('Poppins-BlackItalic.woff') format('woff'),
        url('Poppins-BlackItalic.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: bold;
    src: url('Poppins-Bold.eot');
    src:  url('Poppins-Bold.eot?#') format('embedded-opentype'),
        url('Poppins-Bold.woff') format('woff'),
        url('Poppins-Bold.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins';
    font-style: italic;
    font-weight: bold;
    src: url('Poppins-BoldItalic.eot');
    src:   url('Poppins-BoldItalic.eot?#') format('embedded-opentype'),
        url('Poppins-BoldItalic.woff') format('woff'),
        url('Poppins-BoldItalic.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 900;
    src: url('Poppins-ExtraBold.eot');
    src:   url('Poppins-ExtraBold.eot?#') format('embedded-opentype'),
        url('Poppins-ExtraBold.woff') format('woff'),
        url('Poppins-ExtraBold.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 900;
  src: url('Poppins-ExtraBoldItalic.eot');
  src:  url('Poppins-ExtraBoldItalic.eot?#') format('embedded-opentype'),
      url('Poppins-ExtraBoldItalic.woff') format('woff'),
      url('Poppins-ExtraBoldItalic.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 100;
  src: url('Poppins-ExtraLight.eot');
  src:  url('Poppins-ExtraLight.eot?#') format('embedded-opentype'),
      url('Poppins-ExtraLight.woff') format('woff'),
      url('Poppins-ExtraLight.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 100;
  src: url('Poppins-ExtraLightItalic.eot');
  src:  url('Poppins-ExtraLightItalic.eot?#') format('embedded-opentype'),
      url('Poppins-ExtraLightItalic.woff') format('woff'),
      url('Poppins-ExtraLightItalic.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 200;
  src: url('Poppins-Italic.eot');
  src:  url('Poppins-Italic.eot?#') format('embedded-opentype'),
      url('Poppins-Italic.woff') format('woff'),
      url('Poppins-Italic.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
  src: url('Poppins-Light.eot');
  src: url('Poppins-Light.eot?#') format('embedded-opentype'),
      url('Poppins-Light.woff') format('woff'),
      url('Poppins-Light.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 300;
  src: url('Poppins-LightItalic.eot');
  src:  url('Poppins-LightItalic.eot?#') format('embedded-opentype'),
      url('Poppins-LightItalic.woff') format('woff'),
      url('Poppins-LightItalic.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
  src: url('Poppins-Medium.eot');
  src: url('Poppins-Medium.eot?#') format('embedded-opentype'),
      url('Poppins-Medium.woff') format('woff'),
      url('Poppins-Medium.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins';
    font-style: italic;
    font-weight: 400;
  src: url('Poppins-MediumItalic.eot');
  src:  url('Poppins-MediumItalic.eot?#') format('embedded-opentype'),
      url('Poppins-MediumItalic.woff') format('woff'),
      url('Poppins-MediumItalic.ttf') format('truetype');
}
/* @font-face
{
    font-family: 'Poppins';
  src: url('Poppins-Regular.eot');
  src:  url('Poppins-Regular.eot?#') format('embedded-opentype'),
      url('Poppins-Regular.woff') format('woff'),
      url('Poppins-Regular.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins';
  src: url('Poppins-SemiBold.eot');
  src: url('Poppins-SemiBold.eot?#') format('embedded-opentype'),
      url('Poppins-SemiBold.woff') format('woff'),
      url('Poppins-SemiBold.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins';
  src: url('Poppins-SemiBoldItalic.eot');
  src:  url('Poppins-SemiBoldItalic.eot?#') format('embedded-opentype'),
      url('Poppins-SemiBoldItalic.woff') format('woff'),
      url('Poppins-SemiBoldItalic.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins';
  src: url('Poppins-Thin.eot');
  src:  url('Poppins-Thin.eot?#') format('embedded-opentype'),
      url('Poppins-Thin.woff') format('woff'),
      url('Poppins-Thin.ttf') format('truetype');
}
@font-face
{
    font-family: 'Poppins';
  src: url('Poppins-ThinItalic.eot');
  src: url('Poppins-ThinItalic.eot?#') format('embedded-opentype'),
      url('Poppins-ThinItalic.woff') format('woff'),
      url('Poppins-ThinItalic.ttf') format('truetype');
} */
